<template>
  <!-- 卡片样式 -->
  <div class="box">
    <!-- 卡片的头部 -->
    <div class="flexspb cardhead">
      <div class="title">{{ title }}</div>
      <!-- 右边的更多文字按钮 -->
      <div class="more" @click="morelink">
        <span>{{ more }}</span>
        <i class="el-icon-d-arrow-right"></i>
      </div>
    </div>
    <!-- 内容 -->
    <div class="cardcent">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "card", //卡片
  props: {
    // 卡片标题
    title: {
      typeof: [String],
    },
    // 右边的更多按钮
    more: {
      typeof: [String],
      default: "更多",
    },
  },
  methods: {
    // 点击更多时触发
    morelink() {
      // 传出点击的item
      this.$emit("morelink");
    },
  },
};
</script>


<style scoped lang="less">
@media screen and (min-width: 1024px) {
  // 当前页面宽高
  .box {
    width: 100%;
    height: 100%;
    // 卡片的头部
    .cardhead {
      padding: 0 1.25rem;
      height: 3rem;
      box-sizing: border-box;
      // border-bottom: 0.0625rem solid #dddddd;
      background-image: url(../../assets/cellBg.png);
      background-repeat: no-repeat;
      background-size: 90% 100%;
      color: white;
      .title {
        font-size: 1.5rem;
        font-weight: 400;
      }
      .more {
        font-size: 1rem;
        color: #103aad;
      }
      .more:hover {
        // color: rgba(144, 190, 250, 0.933);
      }
    }
    // 卡片内容
    .cardcent {
      padding: 0.625rem 1.25rem;
      line-height: 2rem;
      letter-spacing: 0.0625rem;
    }
  }
  .box:hover {
    cursor: pointer;
    // box-shadow: 0 0 0.5rem #a1baff;
    // background-color: #ffffff;
  }
}

@media screen and (max-width: 1023px) {
  // 当前页面宽高
  .box {
    width: 100%;
    height: 100%;
    box-shadow: 0 0 0.5rem #dfdeec;

    // 卡片的头部
    .cardhead {
      padding: 0 0.625rem;
      height: 2.5rem;
      box-sizing: border-box;
      border-bottom: 0.0625rem solid #dddddd;

      padding: 0 1.25rem;
      height: 2.5rem;
      box-sizing: border-box;
      // border-bottom: 0.0625rem solid #dddddd;
      background-image: url(../../assets/cellBg.png);
      background-repeat: no-repeat;
      background-size: 90% 100%;
      color: white;
      .title {
        font-size: 1rem;
        font-weight: 550;
      }
      .more {
        font-size: .8rem;
        color: #103aad;
      }
    }
    // 卡片内容
    .cardcent {
      padding: 0.625rem;
      line-height: 2rem;
      letter-spacing: 0.0625rem;
    }
  }
}
</style>
