<template>
  <!-- 导航栏(传入数组) -->
  <div id="homebox">
    <!-- 页面宽度 -->
    <div id="pagebox" class="flexspb">
      <!-- 每一项导航内容 -->
      <div class="menu flexwap" v-for="(item, index) in list" :key="index">
        <!-- 一级菜单 -->
        <div
          @click="changenar(index, 0)"
          class="level flexcent"
          :class="{ lective: primary == index }"
        >
          {{ item.name }}
        </div>
        <!-- 二级菜单 -->
        <div class="child flexwap">
          <!-- 每一项 -->
          <div v-for="(ems, ids) in item.children" :key="ids">
            <div
              @click="changenar(index, ids)"
              class="flexcent"
              :class="{ chctive: secondary == ids && primary == index }"
            >
              <span>{{ ems.name }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "navigation", //导航栏
  props: {
    //导航栏数组
    list: {
      typeof: [Array],
    },
    // 当前的页码(1级菜单)
    pagenum: {
      typeof: [Number],
      default: 0,
    },
    // 当前的页码(1级菜单)
    chinum: {
      typeof: [Number],
      default: 1,
    },
  },
  data() {
    return {
      primary: 0, //一级菜单的选中样式
      secondary: 0, //二级菜单的选中样式
    };
  },
  // 进入页面同步数据
  created() {
    this.primary = this.pagenum; //同步一级菜单
    this.secondary = this.chinum; //同步一级菜单
    console.log('create this.primary', this.pagenum, this.primary)
  },
  methods: {
    // 进入页面加载导航栏数据
    getlist() {
      // 发起请求
      this.$api.getNavigation({}).then((res) => {
        // 把数组放到data中
        this.list = res.data;
      });
    },
    // 点击切换菜单样式
    changenar(index, ids) {
      console.log('list', this.list)
      //一级菜单的选中样式
      this.primary = index
        //二级菜单的选中样式
      this.secondary = ids
      // 传出当前选中菜单
      
      this.$emit("changenar", { pagenum: index, chinum: ids });
      console.log('ids', index, ids, this.primary, this.secondary)
    },
  },
};
</script>

<style scoped lang="less">
@media screen and (min-width: 1024px) {
  // 当前页面
  #homebox {
    height: 3.75rem;
    background-color: #c8d8f3;
    // 每一项导航内容
    .menu {
      flex: 1;
      height: 100%;
      font-size: 1.125rem;
      position: relative;
      // 一级菜单
      .level {
        width: 100%;
        font-weight: 550;
      }
      // 一级菜单的选中状态
      .lective {
        color: #ffffff;
        background-color: #2a579a;
      }
      // 二级菜单
      .child {
        display: block;
        font-size: 1rem;
        position: absolute;
        top: 3.75rem;
        z-index: -1;
        width: 100%;
        opacity: 0;
        // 二级菜单
        .flexcent {
          height: 0rem;
          border-bottom: 0.0625rem solid #ececec;
          padding: 0rem 1rem;
        }
        // 当前二级菜单选中样式
        .chctive {
          background-color: #5f81ca;
          // color: rgb(244, 252, 142);
        }
        // 选中每一项的时候
        .flexcent:hover {
          // background-color: #8894d6;
          // color: rgb(244, 252, 142);
          background-image: linear-gradient(90deg,#1065fb,#1065fb 0,#40b2fb 100%,#40b2fb 0)
        }
      }
    }
    // 鼠标
    .menu:hover {
      cursor: pointer;
      color: #ffffff;
      .level {
        background-color: #2a579a;
      }
      .child {
        z-index: 100;
        transition: opacity 0.5s;
        opacity: 1;
        background-color: #2a579a;
        .flexcent {
          transition: height 0.5s;
          height: 3.75rem;
          font-weight: bolder;
          padding: 0rem 0.5rem;
        }
      }
    }
  }
}
</style>
