<template>
  <!-- 导航栏(传入数组) -->
  <div id="homebox">
    <!-- 页面宽度 -->
    <div id="pagebox" v-if="list.length > 0">
      <div class="navtop flexspb">
        <div>
          <span>{{ list[primary].name }}</span>
          <span v-if="primary != 0">
            > {{ list[primary].children[chinum].name }}</span
          >
          <span v-if="primary != 0" class="rowel">
            <i
              @click="opensecond(issecond)"
              :class="{ secondback: issecond == '2' }"
              class="el-icon-caret-right"
            ></i>
          </span>
        </div>
        <div class="btn flexcent" @click="change(ischange)">
          <i class="el-icon-menu"></i>
        </div>
      </div>
      <!-- 每一项导航内容 -->
      <el-collapse id="modea" accordion v-model="ischange">
        <el-collapse-item id="mediamenu" name="1">
          <div class="menu flexwap" v-for="(item, index) in list" :key="index">
            <!-- 一级菜单 -->
            <div
              @click="changenar(index, 0)"
              class="level flexcent"
              :class="{ lective: primary == index }"
            >
              {{ item.name }}
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
      <!-- 二级导航 -->
      <el-collapse id="modea" accordion v-model="issecond" >
        <el-collapse-item id="mediamenu" name="2">
          <div
            class="menu flexwap"
            v-for="(ems, ids) in list[primary].children"
            :key="ids"
          >
            <!-- 一级菜单 -->
            <div
              @click="changenar(primary, ids)"
              class="level flexcent"
              :class="{ chctive: secondary == ids }"
            >
            <span >{{ ems.name }}</span>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
export default {
  name: "modeio", //导航栏
  props: {
    //导航栏数组
    list: {
      typeof: [Array],
    },
    // 当前的页码(1级菜单)
    pagenum: {
      typeof: [Number],
      default: 0,
    },
    // 当前的页码(1级菜单)
    chinum: {
      typeof: [Number],
      default: 1,
    },
  },
  data() {
    return {
      primary: 0, //一级菜单的选中样式
      secondary: 0, //二级菜单的选中样式
      ischange: "0", //一级菜单的选中样式
      issecond: "0", //二级菜单的选中样式
    };
  },
  // 进入页面同步数据
  created() {
    this.primary = this.pagenum; //同步一级菜单
    this.secondary = this.chinum; //同步一级菜单
  },
  methods: {
    //   显示
    change(e) {
      if (e == "0") {
        this.ischange = "1";
      } else if (e == "1") {
        this.ischange = "0";
      }
    },
    opensecond(e) {
      if (e == "0") {
        this.issecond = "2";
      } else if (e == "2") {
        this.issecond = "0";
      }
    },
    // 进入页面加载导航栏数据
    getlist() {
      // 发起请求
      this.$api.getNavigation({}).then((res) => {
        // 把数组放到data中
        this.list = res.data;
      });
    },
    // 点击切换菜单样式
    changenar(index, ids) {
      //一级菜单的选中样式
      (this.primary = index),
        //二级菜单的选中样式
        (this.secondary = ids);
      // 传出当前选中菜单
      this.$emit("changenar", { pagenum: index, chinum: ids });
    },
  },
};
</script>

<style scoped lang="less">
@media screen and (min-width: 1024px) {
  #homebox {
    display: none;
  }
}
@media screen and (max-width: 1023px) {
  #homebox {
    .navtop {
      width: 100%;
      padding-left: 1.25rem;
      height: 3.5rem;
      box-sizing: border-box;
      background-color: #141fb6;
      color: #ffffff;
      .el-icon-caret-right {
        border: 0.125rem solid #ffffff;
        border-radius: 0.3125rem;
        margin-left: 0.3125rem;
      }
      .secondback {
        box-shadow: 0 0 0.5rem #ffffff;
        transform: rotate(90deg);
        transition-duration: 0.5s;
        -ms-transform: rotate(90deg); /* IE 9 */
        -moz-transform: rotate(90deg); /* Firefox */
        -webkit-transform: rotate(90deg); /* Safari 和 Chrome */
        -o-transform: rotate(90deg); /* Opera */
      }
    }
    /deep/ .el-collapse-item__header {
      display: none;
    }
    .menu {
      height: 3.125rem;
      line-height: 3.125rem;
      font-size: 1rem;
      border-bottom: 0.0625rem solid #777171;
      background-color: #eef7ff;
      // 一级菜单的选中状态
      .lective {
        color: #2837b9;
      }
      .chctive {
        color: #2837b9;
      }
    }
    .child {
      height: 3.125rem;
      line-height: 3.125rem;
      font-size: 1rem;
      border-bottom: 0.0625rem solid #777171;
      background-color: #eef7ff;
    }
    .btn {
      height: 2.5rem;
      margin: 0.625rem;
      width: 2.25rem;
      border-radius: 0.3125rem;
      background-color: #656ef1;
      box-shadow: 0 0 0.125rem #5761f3;
      .el-icon-menu {
        font-size: 1.25rem;
        opacity: 0.6;
      }
    }
  }
}
</style>
