<template>
  <!-- 当前页面为头部组件 -->
  <div>
    <!-- 背景以及logo配置 -->
    <div>
      <logoback @tohome="tohome" />
    </div>
    <!-- 导航栏(传入数组) -->
    <div v-if="istrue">
      <div class="nav">
        <navigation
        @changenar="changenar"
        :list="list"
        :pagenum="primary"
        :chinum="secondary"
      />
      </div>
      <div class="mod">
        <modeio
        @changenar="changenar"
        :list="list"
        :pagenum="primary"
        :chinum="secondary"
      />
      </div>
    </div>
  </div>
</template>

<script>
import logoback from "./logoback"; //背景以及logo配置
import navigation from "./navigation"; //导航栏
import modeio from "./modeio"; //导航栏

export default {
  name: "headerbox", //头部样式
  data() {
    return {
      primary: 0, //一级菜单的选中样式
      secondary: 0, //二级菜单的选中样式
      istrue: true, //刷新导航栏数据
      // ismode:false,//是否显示手机端 
    };
  },
  props: {
    //导航栏数组
    list: {
      typeof: [Array],
    },
    // 当前的页码(1级菜单)
    pagenum: {
      typeof: [Number],
      default: 0,
    },
    // 当前的页码(1级菜单)
    chinum: {
      typeof: [Number],
      default: 1,
    },
  },
  components: {
    logoback, //背景以及logo配置
    navigation, //导航栏
    modeio
  },
  // 进入页面同步数据
  created() {
    // if(window.innerWidth<1024){
    //   this.ismode=true
    // }
    this.primary = this.pagenum; //同步一级菜单
    this.secondary = this.chinum; //同步一级菜单
  },
  methods: {
    // 点击logo进入首页
    tohome() {
      this.primary = 0; //当前显示的页码
      this.secondary = 0; //二级裁断的选中
      this.istrue = false;
      this.$nextTick(() => {
        this.istrue = true;
      });
    },
    // 传出当前选中菜单
    changenar(e) {
      this.$emit("changenar", e);
    },
  },
};
</script>

<style scoped lang="less">
@media screen and (min-width: 1024px) {
 .mod{
   display: none;
 }
}

@media screen and (max-width: 1023px) {
  .nav{
    display: none;
  }
}
</style>
