<template>
  <!-- 导航栏(传入数组) -->
  <div>
    <!-- 尾部导航 -->
    <!-- <div>
      <tailtal :list="list" @changenar='changenar'/>
    </div> -->
    <!-- 尾部备案 -->
    <div>
      <foootxet />
    </div>
  </div>
</template>

<script>
// import tailtal from "./tailtal";
import foootxet from "./foootxet";

export default {
  name: "footerbox", //尾部
  components: {
    // tailtal, //尾部导航
    foootxet,//尾部信息
  },
  props: {
    //导航栏数组
    list: {
      typeof: [Array],
    },
  },
  methods: {
    // 点击切换菜单样式
    changenar(e) {
      // 传出当前选中菜单
      this.$emit("changenar", { pagenum: e.pagenum, chinum: e.chinum });
    },
  },
};
</script>

<style scoped lang="less">
@media screen and (min-width: 1024px) {
  #homebox {
      background-color: #eef7ff;
  }
}

@media screen and (max-width: 1023px) {
}
</style>
