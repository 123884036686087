<template>
  <div id="homebox">
    <!-- 页面宽度 -->
    <div id="pagebox" class="flexspb">
      <div>计算产品性能基准工作组 版权所有</div>
      <div class="foot" @click="tolink('https://beian.miit.gov.cn')">京ICP备05013730号-50</div>
      <div class="foot flexcent" @click="tolink('http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010102004648')">
        <img src="../../assets/beian.png" />
        <span>京公网安备 11010102004648号</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footext", //尾部文字
  methods: {
    tolink(href) {
      window.open(href,'_blank');
    },
  },
    mounted(){
        document.getElementById('cnzz_stat_icon_1280450777').style.display = 'block';
    }
};
</script>

<style scoped lang="less">
@media screen and (min-width: 1024px) {
  // 当前页面
  #homebox {
    height: 8rem;
    background-color: #2a579a !important;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #pagebox {
    height: 3.75rem;
    font-size: 0.9375rem;
    color: white;
    background-color: rgba(27,128,244,.2);
    padding: 0 1.5rem;
    width: 60rem;
    .foot {
      img {
        width: 1.25rem;
        height: 1.25rem;
        margin-right: .625rem;
      }
    }
    .foot:hover {
      cursor: pointer;
      color: #000000;
    }
  }
}

@media screen and (max-width: 1023px) {
  // 当前页面
  #pagebox {
    height: 8rem;
    color: #f3edede7;
    background-color: #5e5e5e;
    flex-wrap: wrap;
    text-align: center;
    font-size: 0.875rem;
    justify-content: center;
    flex-direction: column-reverse;
    div {
      margin-top: 0.3125rem;
    }
    img {
        width: 1rem;
        height: 1rem;
        margin-right: .625rem;
      }
  }
}
</style>
