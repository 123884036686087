<template>
  <!-- 导航栏(传入数组) -->
  <div id="homebox">
    <!-- 页面宽度 -->
    <div id="pagebox" class="flexspb">
      <!-- logo图 -->
      <div class="logoimg" @click="tohome">
        <img src="../../assets/logo.png" alt="" />
      </div>
      <!-- 网站标题 -->
      <!-- <div class="webtitle">计算产品性能基准工作组</div> -->
      <div class="userinfo-lg">
        <span class="tit">计算产品性能基准工作组</span>
        <span class="con">Computing Product Performance Benchmark Workgroup</span>
      </div>
      <!-- 登录 -->
      <div class="logins flexcent" v-if="istrue">
        <span class="login" v-if="!userinfo.username || userinfo.username == ''" @click="login">
        登录
      </span>
      <!-- 用户名 -->
      <div v-if="userinfo.username && userinfo.username != ''" class="flexspb">
        <el-dropdown @command="handleCommand">
          <span class="el-dropdown-link">
            {{ userinfo.username}}<i class="el-icon-arrow-down el-icon--right"  v-if="userInfoNew.defaultPassword != '0'"></i>
          </span>
          <el-dropdown-menu slot="dropdown" class="menu-btn"  v-if="userInfoNew.defaultPassword != '0'">
            <el-dropdown-item  command="1"  v-if="admin">进入后台</el-dropdown-item>
            <el-dropdown-item  command="3">单位信息</el-dropdown-item>
            <el-dropdown-item  command="4">修改密码</el-dropdown-item>
            <el-dropdown-item  command="2">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "logoback", //背景图
  mounted(){
    if(document.getElementById('homeboxBox') && (window.innerWidth < 1023)){
       document.getElementById('homeboxBox').style.minHeight=(window.screen.availHeight-140-128) + 'px';
    }
  },
  methods: {
    // 点击logo跳转到首页
    tohome() {
      // this.$emit("tohome");
      this.until.jump.call(this, "/", "push");
    },
    handleCommand(e){
      if(e==1){
        // 点击登录跳转到后台的登录页面
        this.until.jump.call(this, "/uiadmin", "href");
      }else if(e==2){
        this.istrue=false
        window.sessionStorage.clear()
        window.localStorage.clear()
        this.userinfo={name: ""}
        this.$nextTick(()=>{
          this.istrue=true
        })
      }else if(e == 3){
        this.until.jump.call(this, "/modifyinfo", "push");
      }else if(e==4){
        this.until.jump.call(this, "/password", "push");
      }
    },
    login(){
        this.until.jump.call(this, "/uiadmin", "href");
    }
  },
  data() {
    return {
      userinfo: { name: "" }, //登录成功获取信息
      srcs: this.$base.baseUrl,
      istrue:true,
      userInfoNew:'',
      admin: false,
    };
  },
  created() {
    console.log("------logback-----------")
    var assess_token = this.until.cacheSync("get", "pigx-access_token");
    var info = this.until.cacheSync("get", "pigx-userInfo");
    console.log(" info",info)
    if(assess_token){
      this.$api.getUserYh().then((res) => {
        this.userInfoNew = res.data;

        console.log(" getuseryh,",res)
        this.until.cacheSync("set", 'getUserNew', res.data);
        if (res.data.defaultPassword == '0') {
          this.until.jump.call(this, "/indexpwd", "push");
        }
        console.log(" this.userInfoNew.username",this.userInfoNew.username)
        
        this.$api.getUserRole(this.userInfoNew.username).then((res)=>{
          console.log("  getUserRole res ", res)
          let roles = res.data;
          console.log(" roles ", roles)
          roles.forEach(item => {
            console.log(" role code", item.roleCode)
            if(item.roleCode != 'PORTAL_MEM')
              this.admin = true
          });
        })
        if(this.until.cacheSync("get", "toolInfo")){ // 判斷 書否跳工具下載頁
          this.until.jump.call(this, "/detail", 'push', this.until.cacheSync("get", "toolInfo"));
          this.until.cacheSync("del", 'toolInfo');
        }
      });
    }

    if (info) {
      this.userinfo = info.content;
    }
  },
};
</script>

<style scoped lang="less">
img{
  width: 100%;
  height: 100%;
}
#homebox {
  background-color: #2a579a;
  .logoimg {
    width: 8.75rem;
    height: 2.5rem;
    margin-right: 8.75rem;
  }
  .logoimg:hover {
    cursor: pointer;
  }
  #pagebox {
    height: 5rem;
  }
}
@media screen and (min-width: 1024px) {
  .webtitle {
    flex: 1;
    font-size: 1.875rem;
    letter-spacing: 0.3125rem;
    color: #0a228d;
    font-weight: 550;
  }
  .userinfo-lg {
    flex: 1;
    top: 0;
    cursor: pointer;
    color: #fff;
    display: flex;
    flex-direction: column;
    position: relative;
    transform: translateX(-6rem);
    .tit {
      font-size: 1.5rem;
      font-family: Microsoft YaHei,Arial,Helvetica,sans-serif!important;
    }
    .con {
      font-size: 0.05rem;
      // transform: scale(.7);
      transform: scale(0.8);
      transform-origin: left;
    }
  }
  .round {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    margin-right: 0.625rem;
  }
  .logins{
    width: 14rem;
  }
  .login {
    margin-left: 0.625rem;
    color: white;
  }
  .login:hover {
    cursor: pointer;
    // color: #4c42db;
    // color: white;
  }
  .el-dropdown-link{
    font-size: 1.125rem;
    color: white;
    .el-icon-arrow-down{
      margin-left: .6125rem;
    }
  }

  /deep/.el-dropdown-menu__item{
    width: 4.8125rem;
    text-align: center;
      line-height: 2rem;
      height: 2.2rem;
      font-size: .9rem;
      border-bottom: .0625rem solid #ececec;

    }
}

@media screen and (max-width: 1023px) {
  .webtitle {
    display: none;
  }
  
  .userinfo-lg {
    display: none;    
  }
  .logoimg {
    margin-left: 0.625rem;
    margin-right: 1.75rem!important;
  }
  .round {
    display: none;
  }
  .logins {
    width: 10rem;
    font-size: 1rem;
    /*display: none;*/
  }
  #cnzz_stat_icon_1280450777{
    width: 36px!important;
    display: block!important;
  }
  .menu-btn li{
    line-height: 1.5rem!important;
  }
}
</style>
<style>


</style>
